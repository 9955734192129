<template>
    <AdminHeader />
    <router-view></router-view>
</template>

<script>
import AdminHeader from './header/AdminHeader.vue'
export default {
    components: { AdminHeader },
    setup() {
        
    },
}
</script>